import "./App.css";

function App() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <h1 className="text-4xl font-bold mb-4">🕵️‍♂️ Chaininspector.xyz</h1>
      <h2 className="text-2xl font-bold mb-4">Coming Soon</h2>
      <div className="flex space-x-4">
        <a
          href="https://twitter.com/kiknaio"
          className="bg-blue-500 text-white p-4 rounded"
        >
          Twitter kikna.io 👈 Maker
        </a>
        <a
          href="https://t.me/Chaininspectorxyz"
          className="bg-white text-black p-4 rounded"
        >
          Join Telegram Channel
        </a>
      </div>
    </div>
  );
}

export default App;
